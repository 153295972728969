/* General */
body {
    font-size: 12px;
}

.body-background {
    background-color: black;
    color: white;
    padding-bottom: 1rem;
}

.anchor-lightblue,
.strong-lightblue {
    color: rgb(143, 210, 241);
}

h1.text-center {
    font-size: 2.75rem;
}

.h1-title {
    padding-top: 3.25rem;
}

/* Navbar */
#pagetop .navbar-toggler {
    background-color: white;
    height: 30px;
    font-size: 14px;
    width: 50px;
}

.navlink.navlink-white {
    color: white;
}

.navlink-cursor {
    cursor: pointer;
}

.navlink.navlink-white:hover {
    color: rgb(143, 210, 241);
}

.navlink.navlink-white:active {
    color: white;
}

/* Intro Section */
.homepage-banner {
    /* margin-top: 55px; */
    width: 100%;
    max-width: 1350px;
    min-height: 200px;
    object-fit: cover;
}

.intro-section-image {
    object-fit: cover;
}

.intro-identity-title {
    font-size: 1.75rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

/* Timeline */
.vertical-timeline-element-subtitle {
    font-size: 1.3rem;
    font-style: italic;
}

/* Projects */
.projects-buttons-grid {
    text-align: center;
}

.project-title {
    font-size: 1.6rem;
}

.project-concepts {
    font-size: 1.4rem;
}

.frontend-button {
    margin: auto 1rem;
}

.submit-button.pr-0 {
    padding-right: 0;
    text-align: left;
}

.project-image {
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 0px 15px white;
}

.fa-ml-1 {
    margin-left: 0.5rem;
}

/* Viewports */
@media screen and (min-width: 768px) {
    .intro-identity-title {
        min-height: 4.25rem;
    }

    .intro-section-image {
        width: 10rem;
    }
}

@media screen and (min-width: 1200px) {
    .intro-identity-title {
        min-height: auto;
    }
}

@media screen and (max-width: 576px) {
    .projects-buttons-grid {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .frontend-button {
        margin: 0.5rem auto;
    }


}

@media screen and (max-width: 768px) {
    .intro-section-image {
        width: 6rem;
    }
}